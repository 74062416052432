@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  box-sizing: border-box;
}
.custom-bg {
  background-image: linear-gradient(155deg, rgba(0, 0, 0, 0.65) 31%, rgba(0, 0, 0, 0.65) 95%), url('/src/assets/images/cat.jpg');
  background-size: cover;
  background-position: center;
}

@media (max-width: 768px) {
  .custom-bg {
    background-image: linear-gradient(155deg, rgba(0, 0, 0, 0.65) 31%, rgba(0, 0, 0, 0.65) 95%), url('/src/assets/images/blocks.png');
  }
  .cat-bg{
    background-image: linear-gradient(155deg, rgba(0, 0, 0, 0.65) 31%, rgba(0, 0, 0, 0.65) 95%), url('/src/assets/images/cat.jpg');
  }
}

.parent:hover .childicon{
  text:red;
}
.bullets li{
  margin-bottom:19px
}
.glow {
  text-shadow: 0 0 5px #fff, 0 0 5px #fff, 0 0 5px #fff, 0 0 0px #fff, 0 0 1px #fff, 0 0 18px #fff, 0 0 53px #fff
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  /* font-family: "Montserrat", Arial, Verdana, Sans-Serif; */
}
.item{
  cursor:pointer
}